/* Base Styles */
.heading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 72px;
  color: #ffffff;
  text-align: center;
}

.topContainer {
  position: relative;
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure no overflow issues */
}

.topContainer-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1; /* Background layer */
}

.topContainer-content {
  position: relative;
  z-index: 2; /* Foreground layer */
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  width: 100%;
  height: 100%;
}

.sub-heading {
  width: 90%;
  max-width: 786px;
  height: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.8;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #edeff6;
}

.bottomContainer {
  width: 100%;
  margin-top: 40px; 
}

.bottomLeftContainer,
.bottomRightContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 90%; 
  max-width: 670px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.4;
  color: #3e3e3e;
  text-align: center; 
}

.formContainer {
  margin-top: 20px;
  width: 90%;
  max-width: 670px;
  height: auto;
}

.form {
  width: 100%; 
  border-radius: 5px;
  height: 50px; 
  font-size: 18px; 
}

.formButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #e5be54;
  border: none;
  border-radius: 10px;
  width: 100%;
  font-size: 20px;
  color: white;
  cursor: pointer;
  height: auto;
}

.top-text,
.text-header,
.bottom-text,
.end-text {
  width: 90%;
  max-width: 470px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 18px; 
  line-height: 1.5;
  color: #3e3e3e;
  text-align: center;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .heading {
    font-size: 36px;
    line-height: 1.2;
  }

  .sub-heading {
    font-size: 18px;
    line-height: 1.5;
  }

  .header {
    font-size: 20px;
  }

  .form {
    height: 45px;
    font-size: 16px;
  }

  .formButton {
    padding: 12px;
    font-size: 18px;
  }

  .topContainer {
    height: 500px; /* Adjusted for smaller screens */
  }
  .formContainer{
    width: 180%;
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .top-text,
  .text-header,
  .bottom-text,
  .end-text {
    font-size: 14px;
    line-height: 1.4;
    width: 200%;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 28px;
    line-height: 1.1;
  }

  .sub-heading {
    font-size: 16px;
  }

  .header {
    font-size: 18px;
  }

  .form {
    font-size: 14px;
  }

  .formButton {
    font-size: 16px;
    padding: 10px;
  }
}