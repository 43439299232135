.Container-top {
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: darkgrey;
  background-size: cover;
  background-position: center center;
}

.Container-top-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1; /* Background layer */
}

.Container-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center;
  color: white;
  width: 100%; /* Match parent container width */
  height: 100%; /* Match parent container height */
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensure consistent sizing */
}
.heading-aboutUs {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 72px;
  text-align: center;
  color: #ffffff;
  width: 100%; /* Full width of the parent container */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 0; /* Remove any default margins */
  padding: 0; /* Remove any default paddings */
  box-sizing: border-box; /* Ensure consistent sizing */
}

.first-image {
  width: 670px;
  height: 397px;
  /* margin-left: 375px; */
  margin-top: 60px;
  border-radius: 5px;
  background-image: url("../assets/images/about1.jpg");
  background-size: cover;
  background-position: center center;
}
.second-image {
  position: absolute;
  width: 670px;
  height: 317px;
  background: #ffffff;
  box-shadow: 1px 1px 31px 13px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  top: 100px;
  left: 490px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.heading-text {
  width: 129px;
  height: 72px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 72px;
  /* identical to box height, or 277% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  /* Black */

  color: #3e3e3e;
}
.Div-text {
  width: 595px;
  height: 192px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 32px;
  /* or 188% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Black */

  color: #3e3e3e;
}
.first-image1 {
  width: 670px;
  height: 397px;
  margin-left: 490px;
  margin-top: 143px;
  border-radius: 5px;
  background-image: url("../assets/images/about2.jpg");
  background-size: cover;
  background-position: center center;
  margin-bottom: 40px;
}
.second-image2 {
  position: absolute;
  width: 670px;
  height: 317px;
  background: #ffffff;
  box-shadow: 1px 1px 31px 13px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  top: 183px;
  left: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.heading-text1 {
  width: 145px;
  height: 72px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 72px;
  /* identical to box height, or 277% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  /* Black */

  color: #3e3e3e;
}
.Div-text1 {
  width: 595px;
  height: 224px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 32px;
  /* or 188% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Black */

  color: #3e3e3e;
}
.mission-container {
  width: 1200px;
  position: relative;
}
.mission-alignment {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .Container-top {
    height: 400px;
    padding: 20px;
  }

  .heading-aboutUs {
    width: auto;
    height: auto;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
  }

  .mission-container {
    width: 100%;
    padding: 0 20px;
  }

  /* First Vision Section */
  .first-image {
    width: 100%;
    height: 250px;
    margin: 30px 0 0 0;
  }

  .second-image {
    position: relative;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    padding: 30px 15px;
    margin-top: -30px;
  }

  .heading-text {
    width: auto;
    height: auto;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 15px;
  }

  .Div-text {
    width: 100%;
    height: auto;
    font-size: 16px;
    line-height: 28px;
  }

  /* Second Mission Section */
  .first-image1 {
    width: 100%;
    height: 250px;
    margin: 30px 0 0 0;
  }

  .second-image2 {
    position: relative;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    padding: 30px 15px;
    margin-top: -30px;
  }

  .heading-text1 {
    width: auto;
    height: auto;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 15px;
  }

  .Div-text1 {
    width: 100%;
    height: auto;
    font-size: 16px;
    line-height: 28px;
  }
}

/* Additional styles for very small screens */
@media screen and (max-width: 480px) {
  .Container-top {
    height: 300px;
  }

  .heading-aboutUs {
    font-size: 32px;
    line-height: 42px;
  }

  .first-image,
  .first-image1 {
    height: 200px;
  }

  .second-image,
  .second-image2 {
    padding: 20px 15px;
  }
}

/* Fix for medium-sized screens */
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .mission-container {
    width: 90%;
  }

  .first-image,
  .first-image1 {
    width: 50%;
  }

  .second-image,
  .second-image2 {
    width: 50%;
  }
}