.properties-main-image-container {
  background: url("/public/icons/properties.webp");
  width: 100%;
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
}
.pagination-alignment {
  margin-top: 50px;
  margin-bottom: 30px;
}

.Top-left {
  display: flex;
  flex-direction: column;
}
.contact-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17px;
  width: 70px;
  height: 34px;
  background: #ffffff;
  border-radius: 10px;
}
.input-styling {
  width: 318px;
  height: 54px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.heading-contact {
  width: 318px;
  height: 22px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  /* identical to box height, or 92% */

  display: flex;
  align-items: flex-end;
  text-align: center;
  text-transform: uppercase;

  color: #3a3a3a;
}
.contact-box-content {
  width: 318px;
  height: 92px;
  top: 66px;

  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.contact-button {
  width: 318px;
  height: 50px;
  border: 0px !important;
  background: #e5be54 !important;
  border-radius: 5px;
}
.button-text {
  width: 58px;
  height: 22px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #ffffff;
}
.agent-div {
  position: relative;
  width: 120px;
  height: 100%;
}
.agent-text {
  width: 100%;
  height: 80px;
  padding-left: 26px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #3a3a3a;
}
.agent-image {
  width: 83px;
  height: 84px;
  /* margin-top: 86px; */
  margin-left: 26px;
  position: absolute;
}
.agent-box {
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 218px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-left: 30px;
  margin-top: 100px;
}
.agent-details {
  width: 100%;
  height: 84px;
  display: flex;
}
.s-description {
  width: 770px;
  margin-top: 20px;
}

.font-style {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */

  display: flex;
  align-items: flex-end;

  color: #3a3a3a;
}
.price-detail {
  width: 221px;
  height: 22px;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  /* identical to box height, or 92% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #3a3a3a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 15px;
}
.location-detail {
  width: 315px;
  height: 22px;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #3a3a3a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 15px;
}
.details-type {
  width: 200px;
  height: 22px;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #3a3a3a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 15px;
}
.house-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 50px 24px 49px;
  gap: 50px;

  width: 570px;
  height: 124px;

  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.box-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  /* identical to box height, or 145% */

  display: flex;
  align-items: flex-end;

  color: #3a3a3a;

  /* Inside auto layout */

  /* flex: none;
  order: 1;
  flex-grow: 0; */
  margin-top: 10px;
}
.box-div {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.back-screen {
  display: flex;
  align-items: center;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  cursor: pointer;
  width: 100%;
  height: 100px;
  color: #3a3a3a;
}
.first_col {
  width: 770px;
}
.ant-card-body {
  padding: 0px !important;
}
.lower-images {
  width: 170px;
  height: 96px;
  border-radius: 6px;
}
.description {
  width: 1325px;
}
.agent-name {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #3a3a3a;

  padding-left: 20px;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.listing-agent-details {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #3a3a3a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  padding-left: 10px;
}
.description-section {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  /* identical to box height, or 123% */

  display: flex;
  align-items: flex-end;

  color: #3a3a3a;
  margin-bottom: 30px;
}

.ant-input {
  padding: 13px !important;
}

.ant-select-selection-item {
  height: 48px;
  line-height: 48px; 
  display: flex;
  align-items: center;
}

.ant-input-number .ant-input-number-input {
  height: 48px;
  line-height: 48px; 
  display: flex;
  align-items: center;
}

.rating-container {
  background-color: white;
  padding: 0px 7px;
}

.carousel-container {
  margin: 20px;
  width: 100%;
  position: relative;
}

.carousel-image-large {
  width: 100%;
  height: 750px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

.carousel-image-small {
  width: 100%;
  height: 367px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}

.image-count {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  font-size: 18px;
}

.image-count span {
  margin-left: 8px;
}

.carousel-fullscreen .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-slide {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden;
}

.modal-image {
  width: auto; 
  max-width: 100%; 
  height: auto; 
  max-height: 100vh; 
  min-height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: contain;
  z-index: 3000;
}

.image-gallery-modal .ant-modal-content {
  background-color: black !important;
  position: relative;
  border-radius: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible !important; 
  z-index: 10001 !important;
}

.image-gallery-modal .ant-modal-close {
  position: absolute; /* Ensure proper positioning */
  top: 16px; /* Adjust for spacing */
  right: 16px; /* Adjust for spacing */
  z-index: 10002; /* Ensure it's above modal elements */
  color: white !important; /* Make it visible on dark background */
  font-size: 24px; /* Larger size for better visibility */
  background: transparent; /* Remove any background */
  border: none; /* Ensure no border */
  outline: none; /* Remove outline */
  cursor: pointer; /* Make it clickable */
  transition: color 0.3s ease, background-color 0.3s ease; /* Add hover effect */
}

.image-gallery-modal .ant-modal-close:hover {
  color: #e5be54; /* Change color on hover for feedback */
}

.image-gallery-modal .ant-modal-body {
  padding: 0 !important;
  overflow: hidden !important;
  z-index: 10001 !important;
}

:where(.css-dev-only-do-not-override-10ed4xt).ant-modal .ant-modal-close {
  position: absolute;
  top: 17px;
  inset-inline-end: 17px; 
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border-radius: 4px;
  width: 22px;
  height: 22px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
}

.carousel-fullscreen .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh; 
  background-color: black;
}

.thumbnails {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  margin-right: 90px;
  overflow-x: auto;
  scrollbar-width: none;
}

.thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.thumbnail.active {
  border: 2px solid white;
  opacity: 1;
}

.thumbnails::-webkit-scrollbar {
  display: none; 
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  font-size: 24px;
  color: #000;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
}

.custom-arrow-left {
  left: 10px;
}

.custom-arrow-right {
  right: 10px;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 24px;
  z-index: 2;
  cursor: pointer;
}

.thumbnail-wrapper {
  position: relative;
  display: inline-block;
}

.thumbnails-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: black;
  position: relative;
}

.thumbnail-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transform: translateX(20%);
  color: white;
  font-size: 18px;
  cursor: pointer;
  z-index: 2;
}

.close-icon {
  position: absolute; /* Ensure it's placed relative to the modal */
  top: 16px; /* Add spacing from the top */
  right: 16px; /* Add spacing from the right */
  font-size: 24px; /* Default size for large screens */
  color: white !important; /* Ensure it contrasts with the modal background */
  z-index: 10002 !important; /* Higher than other modal elements */
  cursor: pointer; /* Make it clickable */
}

.image-count {
  position: absolute;
  bottom: 15px;
  left: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  font-size: 18px;
}

.image-count span {
  margin-left: 8px;
}

.modal-content-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0; 
  overflow: hidden;
}

@media (max-width: 768px) {
  .carousel-container {
    margin: 10px;
  }

  .carousel-image-large {
    width: 100%;
    height: auto;
    max-height: 300px; 
    object-fit: cover;
  }

  .carousel-image-small {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .image-count {
    bottom: 10px;
    left: 10px;
    padding: 5px;
    font-size: 14px;
  }

  .image-gallery-modal .ant-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }

  .image-gallery-modal .ant-modal-body {
    overflow: hidden; 
  }

  .image-gallery-modal .ant-modal-close {
    top: 12px; /* Adjust for smaller screens */
    right: 12px; /* Adjust for smaller screens */
    font-size: 20px; /* Slightly smaller for mobile */
  }

  .modal-image {
    width: auto; 
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    display: block;
    margin: 0 auto;
  }

  .fullscreen-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: black;
    overflow: hidden;
  }

  .thumbnails {
    display: none; 
  }

  .thumbnail {
    width: 100px; 
    height: 100px;
    margin-right: 8px; 
  }

  .carousel-container .ant-col:nth-child(2) {
    display: none;
  }

  .carousel-container .ant-col:nth-child(1) {
    width: 100%;
    margin-right: 17px;
  }

  .carousel-arrow {
    display: none;
  }

  .custom-arrow {
    font-size: 20px;
    padding: 5px;
  }

  .thumbnail-wrapper {
    flex-shrink: 0; 
  }

  .close-icon {
    margin-right: 20px;
    font-size: 20px;
    z-index: 10002 !important;
  }

  .image-count span {
    font-size: 12px; 
  }

  .carousel-navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }

  .thumbnails-wrapper {
    padding: 5px;
  }
}