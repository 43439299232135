.home-section-1 {
  background: url("/public/icons/section1.webp"), #f5f5f5;
  width: 97%;
  height: auto;
  min-height: 1050px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 90px auto 10px auto;
  border-radius: 14px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001 !important;
  background: #ffffff;
}

.blog-section {
  position: relative; /* Enable layering */
  width: 100%;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden; /* Prevent overflow issues */
}

.blog-section-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.7);
  object-fit: cover;
  z-index: 1; /* Place the image in the background */
}

.blog-section-content {
  position: relative;
  z-index: 2; /* Place the content on top of the background */
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blog-page-top {
  font-family: "Nunito";
  font-weight: 700;
  font-size: 3.2rem;
  color: white;
}
.blog-top-subheading {
  font-family: "Nunito";
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 80%;
  line-height: 1.8;
}

.home-header-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(255, 255, 255);
  max-width: 1940px;
  margin: 0;
  z-index: 2000 !important; 
}

@media screen and (min-width: 1600px) {
  .home-header-container {
    margin: 0 auto; 
  }
}

.header-alignment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  font-size: 17px !important;
  color: white;
}
.home-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
  z-index: 1001; 
  order: 2;
}
.home-heading-container {
  padding-top: 14vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: auto;
  color: white;
  margin-bottom: 2vw;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 2.5rem;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 90vw) {
  @supports (-webkit-appearance: none) and (not (-moz-appearance: none)) {
    .home-heading-container {
      padding-bottom: 20px; /* Apply margin-bottom only in Chrome */
    }
  }
}

.contact-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-button:hover {
  color: white !important;
}

.col-alignment {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1607px){
  .home-heading-container {
    padding-bottom: 20px;
 }
}
@media (max-width: 1287px){
  .home-heading-container {
    padding-bottom: 50px;
 }
}
@media (max-width: 1020px){
  .home-heading-container {
    padding-bottom: 100px;
 }
}
@media (max-width: 1000px) {
  .home-heading-container {
    padding-top: 17vw;
    display: flex;
    justify-content: center;
    margin-top: auto;
    color: white;
    margin-bottom: auto;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 2rem;
  }
  @media (max-width: 882px){
    .home-heading-container {
      padding-bottom: 120px;
   }
  }
  @media (max-width: 785px){
    .home-heading-container {
      padding-bottom: 150px;
   }
  }
  @media (max-width: 708px){
    .home-heading-container {
      padding-bottom: 170px;
   }
  }
  @media (max-width: 703px) {
    .home-heading-container {
      font-size: 2.5rem !important;
      line-height: 38px !important;
    }
  }
  @media (max-width: 480px) {
    .home-heading-container {
      padding-top: 100px !important;
      font-size: 24px !important;
      line-height: 34px !important;
      padding-left: 25px;
    }
    .home-section-1 {
      background: url("/public/icons/section1.png"), #f5f5f5;
      width: 97%;
      height: auto;
      min-height: 800px;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 90px auto 10px auto;
      border-radius: 14px;
    }
  }
  .home-paragraph-container p {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.4;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #edeff6;
    width: 90%;
    height: auto;
    padding: 0 15px;
  }
  @media (max-width: 1000px) {
    .home-paragraph-container p {
      font-size: 18px;
      line-height: 26px;
      width: 100%;
    }
  }
  
  @media (max-width: 700px) {
    .home-paragraph-container p {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .home-paragraph-container p {
      font-size: 14px;
      line-height: 22px;
      width: 100%;
    }
  }
  .home-search-row {
    padding: 5% !important;
  }
  .home-feature-card {
    padding: 30px !important;
  }
}
@media (max-width: 700px) {
  .home-heading-container {
    padding-top: 100px !important;
    padding-bottom: 80px;
    font-size: 25px !important;
    line-height: 42px !important;
  }
  .home-paragraph-container p {
    font-size: 16px;
    width: 100% !important;
    height: auto ;
  }
}
.hamMenu-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; 
  background-color: rgba(0, 0, 0, 0.9); 
  color: white;
  z-index: 600; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.ham-alignment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 18px;
  margin-top: 10px;
}
.home-paragraph-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.home-paragraph-container p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #edeff6;
  width: 40%;
  height: 60px;
}
.link-style {
  color: white;
  text-decoration: none;
  font-size: 20px;
  text-align: center;
}

.home-search-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.home-search-row {
  background-color: white;
  height: auto !important;
  border-radius: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;
  margin: 1rem;
  width: 90%;
}
.home-search-row1 {
  background-color: white;
  height: auto !important;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px;
  margin: 20px;
  width: 850px;
}

.search-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 1rem;
  width: 156px;
  height: 50px;
  background: #e5be54;
  border-radius: 0.5rem;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  color: #ffffff !important;
  border: none !important;
}
.reset-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 156px;
  height: 50px;
  background: rgb(118, 189, 12);
  border-radius: 5.78475px;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #ffffff !important;
  border: none !important;
}

.logo-image {
  object-fit: contain;
  width: 150px;
  max-width: 150px;
  margin: 10px;
  margin-left: 0;
  z-index: 1000;
}
@media (max-width: 576px) {
  .logo-image {
    max-width: 130px; 
    margin-left: 10px;
  }
}
.ant-select-selector {
  height: 50px !important;
  width: 100% !important;
}
.ant-select-selection-placeholder {
  padding-top: 8px !important;
}
.ant-message {
  z-index: 2000 !important; 
}
.hamburgerMenu {
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 9999;
  display: none; 
  order: 1;
}
@media (max-width: 576px) {
  .hamburgerMenu {
    display: block;
    margin-left: 10px;
    z-index: 9999;
  }
}
@media (max-width: 480px) {
  .home-search-row {
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
  }

  .search-button-container {
    width: 100%;
    font-size: 0.9rem;
  }
}
.logo-and-hamburger {
  display: flex;
  justify-content: space-between; /* Separate the logo and menu */
  align-items: center;
  padding: 0 10px;
}
@media (max-width: 1210px) {
  .hamburgerMenu {
    display: block;
    z-index: 5000;
  }
  .dashboard-icon {
    margin-top: 0 !important; 
  }
}
.mobileView {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}

/* property */

.property-heading-container {
  padding-top: 60px;
}
.property-heading-container p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #e5be54;
}
.property-paragraph-container {
  padding-top: 8px;
}
.property-paragraph-container p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #3e3e3e;
}
.card {
  width: 90%;
  max-width: 340px;
  height: 410px;
  margin: 1rem auto;
  border-radius: 0.5rem;
  box-shadow: 0px 3.63636px 11.8182px 0.909091px rgba(0, 0, 0, 0.15);
  margin-top: 20px;
}
@media (max-width: 400px) {
  .card {
    max-width: 350px;
  }
}
.property-card-image-text-container {
  width: 100%;
  height: 250px;
  position: relative;
}
.dashboard-property-image {
  width: 150px;
  height: 150px;
  position: relative;
}

.property-card-image-text {
  width: auto;
  padding: 0.5rem 1rem;
  height: 22px;
  background: #e5be54;
  border-radius: 0.2rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
}
.property-card-like {
  position: absolute;
  top: 10px;
  right: 10px;
}

.property-card-image-text p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 10.9091px;
  text-align: center;
  color: #ffffff;
}
.card:hover {
  box-shadow: 3px 1.63636px 11px 2.8182px rgba(0, 0, 0, 0.15);
}
.card-state-description {
  margin-top: 18px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16.3636px;
  line-clamp: 1;
  line-height: 22px;
  margin-left: 22px;
  margin-right: auto;
  color: black;
  display: -webkit-box; /* Required for line-clamp to work */
  -webkit-line-clamp: 1; /* Clamp the text to 1 line */
  -webkit-box-orient: vertical; /* Required to orient the box vertically */
  overflow: hidden; /* Ensures text doesn't overflow */
  text-overflow: ellipsis; /* Adds ellipsis for truncated text */
}
.card-state-name {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 22.7273px;
  line-height: 31px;
  margin-left: 22px;
  margin-top: 5px;
  color: #e5be54;
}
.card-location-container {
  padding-top: 5px;
  padding-left: 22px;
  display: flex;
}
.card-location-container p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 12.7273px;
  line-height: 17px;
  text-align: center;
  color: #3e3e3e;
  margin-left: 9px;
}
.property-apartments-detail-container {
  display: flex;
  gap: 10px; 
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 10px;
}
.property-beds-container {
  display: flex;
  align-items: center; 
  gap: 2px; 
}

.property-beds-container img {
  width: 16px;
  height: 13px;
}
.property-beds-container span {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 12.7273px;
  line-height: 17px;
  text-align: center;
  color: #3e3e3e;
}
.property-view-all-button-conatainer {
  padding-top: 50px;
  margin-right: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.property-view-all-button-conatainer button {
  padding: 0.5rem 1rem;
  gap: 10px;
  width: 101px;
  height: 44px;
  background: #e5be54;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border-radius: 0.3rem;
}
.property-view-all-button-conatainer button:hover {
  background: #e19948;
}
/* feature */
.home-feature-container {
  height: auto;
  width: 100%;
  background: #0b2c3d;
}
.feature-heading-container {
  padding-top: 43px;
}
.feature-heading-container p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #e5be54;
}
.feature-text-container {
  padding-top: 8px;
}
.feature-text-container p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: hsl(0, 0%, 100%);
}
.home-feature-card {
  display: flex;
  justify-content: center;
  padding: 60px;
}
.feature-card {
  width: 80%;
  max-width: 300px;
  height: 265px;
  background: #ffffff;
  border-radius: 0.5rem;
  margin: 1rem auto;
  padding: 1rem;
}
.feature-card-icon-main-container {
  padding-top: 30px;
}
.feature-card-icon-container {
  width: 101.22px;
  height: 101.22px;
  border-radius: 50%;
  background: rgba(229, 190, 84, 0.2);
}
.feature-card-icon-container img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.feature-card-icon-main-container {
  display: flex;
  justify-content: center;
}
.feature-card-text-container {
  padding-top: 12px;
  display: flex;
  justify-content: center;
}
.feature-card-text-container-2 {
  padding-top: 12px;
  width: 205px;
  margin: 0 auto;
  height: 60px;
}
.feature-card-text-container b {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14.4605px;
  line-height: 20px;
  text-align: center;
  color: #3e3e3e;
}
.feature-card-text-container-2 p {
  font-family: "Nunito";
  font-style: normal;
  align-items: center;
  margin: auto;
  font-weight: 300;
  font-size: 14.4605px;
  line-height: 20px;
  text-align: center;
  color: #3e3e3e;
}
.card-col {
  display: flex;
  justify-content: center;
}
.ant-carousel .slick-dots li button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
}
.card-image {
  width: 100% !important;
  height: 250px !important;
  object-fit: cover;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.otp-timer {
  font-size: 1.25rem;
  color: #e5be54;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  margin-top: 10px;
}

/* team */
.team-heading-container {
  padding-top: 60px;
}
.team-heading-container p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  line-height: 33px;
  color: #e5be54;
}
.team-heading-container-2 {
  padding-top: 4px;
}
.team-heading-container-2 p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;

  color: #3e3e3e;
}
.team-heading-container-3 {
  padding-top: 8px;
  padding-bottom: 30px;
  width: 100%;
}
.team-heading-container-3 p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  margin: auto;
  color: #3e3e3e;
  padding-top: 8px;
  max-width: 594px;
}
.single-team-image-container {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(229, 190, 84, 0.4) 0%,
    rgba(229, 190, 84, 0) 100%
  );
  border-radius: 15px;
}
.team-image-container {
  display: flex;
  justify-content: center;
}
.team-image-container img {
  height: 324px;
}
.team-designation-container {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}
.team-designation-container p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 31px;
  color: #3e3e3e;
}

/* Home Contect Us */
.home-contact-us-container {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  gap: 1rem;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  flex-wrap: wrap; 
}
.home-contact-us-text {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  margin-top: 20px;
  margin-left: 115px;
}
@media (max-width: 576px){
  .home-contact-us-text {
    margin-left: 0;
  }
}
.home-contact-us-heading {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 28px;
  color: #333;
}
.home-contact-us-paragraph {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}
.home-contact-us-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  padding-top: 20px;
  margin-right: 10%;
}
@media (max-width: 576px){
  .home-contact-us-buttons {
    padding-top: 0px;
  }
}
.contact-us-button {
  width: 258px;
  height: 50px;
  border: solid 1px #010101;
  background: #fffefc !important;
  border-radius: 5px;
  text-transform: uppercase;
}
.contact-us-button:hover {
  border-color: #e5be54 !important;
  color: #e5be54 !important;
}
@media (max-width: 768px) {
  .home-contact-us-container {
    padding: 20px 30px;
    flex-direction: column;
    align-items: flex-start;
  }
  .home-contact-us-text {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .home-contact-us-buttons {
    justify-content: center;
    margin-right: 0;
    width: 100%;
  }
  .contact-button {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .home-contact-us-heading {
    font-size: 24px;
  }
  .home-contact-us-paragraph {
    font-size: 14px;
  }
  .contact-button {
    padding: 10px 15px;
    font-size: 12px;
  }
}
a {
  color: white;
  text-decoration: none;
}

.enClass {
  background-color: #e5be54 !important;
  color: white !important;
}

.arClass {
  color: #e5be54 !important;
  background-color: white !important;
}

.enClass:hover {
  border-color: transparent !important;
  background-color: #f7fafcdf !important;
}

.arClass:hover {
  border-color: transparent !important;
  background-color: #f7fafcdf!important;
}

.language_button_position {
  position: absolute;
  top: 40px;
  right: 40px;
}

.Loader-div {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no_data_found {
  font-size: 25px;
}

.property-type1 {
  padding-top: 0px;
}

.property-type {
  padding-top: 0px;
}

.margin-bottom {
  margin-bottom: 0px !important;
}

.user-icon, .user-login-icon {
  font-size: 40px !important; /* Fixed size */
  color: rgb(0, 0, 0) !important;
  cursor: pointer;
  margin-right: 10px;
}

.user-login-col {
  display: flex;
  align-items: center;
  height: 100%; 
}

@media (max-width: 576px) {
  .user-login-col {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%; 
  }
}
.user-icon-text {
  color: rgb(0, 0, 0);
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  font-family: "Nunito";
}

@media (max-width: 1400px) {
  .user-icon-text {
    margin-left: -70px; 
  }
  .user-login-icon {
    margin-right: 10px; /* Only margin changes */
  }
  .user-icon {
    margin-right: 45px; /* Only margin changes */
  }
}

@media (max-width: 576px) {
  .user-icon {
    margin-right: 45px; /* Only margin changes */
  }
  .user-login-icon {
    margin-right: 10px; /* Only margin changes */
  }
  .user-icon-text {
    margin-left: -60px; /* Only margin changes */
  }
}
.tab-button {
  width: 100% !important;
  height: 50px !important;
  border: 0px !important;
  font-size: 22px !important;
  font-weight: 500 !important;
}
.tab-bg-selected {
  background-color: #e5be54 !important;
  color: white !important;
}
.tab-bg-unselected {
  background-color: white !important;
  border: 1px solid #e5be54 !important;
  color: black !important;
}
.tab-row {
  padding: 5px;
  border-radius: 5px;
  margin-top: 20px;
}
.form-align {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.input-style {
  width: 300px !important;
  height: 50px !important;
}

.like-button {
  font-size: 30px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  transition: transform 0.5s ease, color 0.5s ease;
}

.like-button:hover {
  transform: scale(1.5);
}

.like-button.liked {
  /* color: #e5be54; */
  color: red;
  transform: scale(1.3);
}
.registerMessage {
  color: red;
  font-size: 13px;
  text-align: center;
}

.table_heading {
  font-family: Nunito;
}

.blog-card {
  display: grid;
  margin-top: 70px;
}
.blog-image {
  max-width: 350px;
  height: 300px;
  object-fit: contain;
}

.blog-page-image {
  max-width: 550px;
  height: 300px;
  object-fit: contain;
}

.blog-details-image {
  max-width: 70%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 140px
}

.blog-details-image-2 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.blog-image-2 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.blog-page-image-2 {
  width: 80%;
  height: 100%;
  border-radius: 10px;
}
/* .blog-image-2:hover {
  cursor: pointer;
} */
.read-more:hover {
  border: 1px solid #e5be54;
  width: 100px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Nunito";
  font-weight: 600;
  background-color: #e5be54;
  color: white;
  margin-top: 20px;
}

.blog-date {
  font-size: 15px;
  margin: 15px;
  width: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Nunito";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-title {
  font-family: "Nunito";
  font-size: 25px;
  font-weight: 600;
  width: 350px;
  height: 120px;
  text-align: left;
  margin-top: 30px;
}

.read-more-container {
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.read-more {
  border: 1px solid #e5be54;
  width: 100px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Nunito";
  font-weight: 600;
  color: #e5be54;
  margin-top: 20px;
}

.blog-details {
  font-family: "Nunito";
  margin-top: 30px;
  margin-left: 7%;
  margin-right: 7%;
  text-align: justify;
  padding-bottom: 30px;
}

.blogTitle {
  font-family: "Nunito";
  font-size: 30px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
}

@media screen and (max-width: 768px) {
  .blog-section {
    height: 400px;
    padding: 20px;
  }

  .blog-page-top {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .blog-top-subheading {
    font-size: 1rem;
    max-width: 90%;
  }

  .blog-card {
    width: 100%;
    margin: 15px auto;
  }

  .blog-image {
    width: 100%;
    height: 200px;
  }

  .blog-page-image {
    width: 100%;
    height: 200px;
  }

  .blog-image-2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blog-page-image-2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blogTitle {
    font-family: "Nunito";
    font-size: 30px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 80px; 
    text-align: center;
  }

  .blog-title {
    width: 100%;
    height: auto;
    font-size: 20px;
    margin-top: 0px;
    line-clamp: 1;
    padding: 0 0px;
    display: -webkit-box; /* Required for line-clamp to work */
    -webkit-line-clamp: 1; /* Clamp the text to 1 line */
    -webkit-box-orient: vertical; /* Required to orient the box vertically */
    overflow: hidden; /* Ensures text doesn't overflow */
    text-overflow: ellipsis; /* Adds ellipsis for truncated text */
  }

  .blog-date {
    margin: 10px;
    width: auto;
    font-size: 14px;
  }

  .blog-details-image {
    max-width: 70%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 140px;
  }
  
  .blog-details-image-2 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .read-more {
    margin: 15px auto;
  }
}

/* Small mobile devices */
@media screen and (max-width: 480px) {
  .blog-section {
    height: 300px;
  }

  .blog-page-top {
    font-size: 1.75rem;
  }

  .blog-top-subheading {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  .blog-card {
    width: 100%;
  }

  .blog-image {
    height: 180px;
  }

  .blog-page-image {
    height: 180px;
  }

  .blogTitle {
    font-family: "Nunito";
    font-size: 30px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    text-align: center;
  }

  .blog-details-image {
    max-width: 70%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 140px
  }
  
  .blog-details-image-2 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .blog-title {
    font-size: 18px;
  }
}

/* Medium devices (tablets) */
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .blog-section {
    height: 500px;
  }

  .blog-card {
    width: 95%;
  }

  .blog-title {
    width: 100%;
    font-size: 22px;
  }

  .blog-details-image {
    max-width: 70%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 140px;
  }
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #e5be54 !important;
}
.ant-tabs .ant-tabs-tab :hover {
  color: #e5be54 !important;
}

.ant-tabs-ink-bar {
  background: #e5be54 !important;
}

.dashboard-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  margin-top: 0; 
  color: white;
}

.explore-apartments {
  padding: 40px;
  background-color: #f5f5f5;
}

.property-section-header {
  text-align: left;
  margin-bottom: 20px;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  color: #000;
}

.description {
  font-size: 1rem;
  color: #888;
}

.property-section {
  margin-top: 20px;
}

.filter-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-buttons button {
  padding: 10px 20px;
  border: 2px solid #000;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
}

.filter-buttons button:hover {
  background-color: #000;
  color: #fff;
}

.property-cards {
  display: flex;
  justify-content: space-between;
}

.property-card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  text-align: left;
  width: 18%;
}

.card-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card-info {
  padding: 10px;
}

.card-info h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
  margin: 0;
}

.card-info p {
  font-size: 1rem;
  color: #888;
}

@media (max-width: 768px) {
  .property-cards {
    flex-direction: column;
    align-items: center;
  }

  .property-card {
    width: 100%;
    margin-bottom: 20px;
  }
}
