.footer-main-container {
  display: flex;
  justify-content: space-between;
  background-color: #0B0E13;
  color: #FFFFFF;
  padding: 50px 0;
  flex-wrap: wrap;
}
.footer-left, .footer-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  padding-left: 100px;
}
.footer-heading {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.footer-heading-discovery {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
  margin-bottom: 20px;
  margin-right: 20px;
}

.footer-link {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #A0A0A0;
  margin-top: 10px;
  cursor: pointer;
}
.footer-link:hover {
  color: #FFFFFF;
}
.app-links, .social-media-links {
  display: flex;
  gap: 10px;
}
.app-icon {
  width: 100px;
}
.social-icon {
  width: 40px;
}
.customer-support, .live-support {
  font-size: 14px;
  color: #A0A0A0;
  margin-top: 15px;
}
.customer-support br + * {
  text-align: center;
  display: block;
}

.live-support br + * {
  text-align: center;
  display: block;
}
.newsletter-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.newsletter-input-container {
  display: flex;
  gap: 0px;
  margin-top: 5px;
  width: 76%;
}
.email-input {
  flex: 1;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  border: none;
  height: 100%;
  background-color: #4F4F4F;
  color: #FFFFFF;
}
.email-input::placeholder {
  color: white;
}
.subscribe-button {
  padding: 10px 20px;
  background-color: #4F4F4F;
  border: none;
  border-radius: 0 5px 5px 0;
  height: 100%;
  cursor: pointer;
  color: #FFFFFF;
}
.footer-listing-container {
  display: flex;
  gap: 115px;
  margin-top: 50px;
}
.footer-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.footer-link {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #A0A0A0;
  margin-top: 10px;
  cursor: pointer;
}
.footer-link:hover {
  color: #FFFFFF;
}
.subscribe-button:hover {
  background-color: #8B8577;
}

@media (max-width: 1400px) {
  .footer-listing-container {
    gap: 100px;
    margin-right: 30px;
  }
}

@media (max-width: 1200px) {
  .footer-main-container {
    padding: 30px 0;
  }
  .footer-left, .footer-right {
    padding-left: 100px;
  }
  .footer-listing-container {
    flex-wrap: wrap;
    gap: 100px;
  }
  .newsletter-input-container {
    width: 80%;
  }
}
@media (max-width: 1000px) {
  .footer-main-container {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
  .footer-heading {
    font-size: 16px;
    text-align: center;  
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .footer-left, .footer-right {
    padding-left: 0;
    align-items: center;
  }
  .footer-listing-container {
    flex-wrap: wrap;
    gap: 20px;
    margin-right: 30px;
  }
  .customer-support, .live-support {
    font-size: 14px;
    color: #A0A0A0;
    margin-top: 15px;
    text-align: center;
  }
  .newsletter-input-container {
    width: 100%;
  }
  .footer-listing-alignment {
    flex: 1;
    min-width: auto;
  }
  .subscribe-button {
    padding: 9px 14px;
  }
}
@media (max-width: 600px) {
  .footer-divider-container {
    padding-left: 5%;
    padding-right: 5%;
  }
  
  .footer-heading {
    font-size: 16px;
    text-align: center;  
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  .footer-link {
    font-size: 14px;
    text-align: center; 
  }
  .footer-listing-alignment {
    flex: 1;
    padding-right: 20px;
  }
  .app-icon {
    width: 80px;  
  }
  .social-icon {
    width: 35px;  
  }
  .customer-support, .live-support {
    font-size: 14px;
    color: #A0A0A0;
    margin-top: 15px;
    text-align: center;
  }
  .subscribe-button {
    padding: 9px 14px;
  }
  .social-media-links {
    justify-content: center;  
  }
  .footer-list {
    text-align: center; 
  }
  .social-media-links{
    margin-bottom: 20px;
  }
}
}

@media (max-width: 400px) {
  .footer-main-container {
    padding: 20px 10px;  
  }
  .footer-heading {
    font-size: 14px; 
  }
  
  .footer-link {
    font-size: 12px;
    margin-top: 8px; 
  }
  .customer-support, .live-support {
    font-size: 14px;
    color: #A0A0A0;
    margin-top: 15px;
    text-align: center;
  }
  .app-icon {
    width: 70px;  
  }
  .social-icon {
    width: 30px;  
  }
  .subscribe-button {
    padding: 9px 14px;
  }
  .newsletter-input-container {
    flex-direction: column;
    width: 100%;
    gap: 10px;  
  }
  .email-input, .subscribe-button {
    width: 100%;
    border-radius: 5px;
  }
  .footer-listing-container {
    margin-top: 30px;
    gap: 15px; 
  }
  .social-media-links{
    margin-bottom: 20px;
  }
}